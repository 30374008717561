import { CampaignMutation } from '@/store/mutationTypes';

export default {
  [CampaignMutation.ADD](state, item) {
    state.campaigns.unshift(item);
  },
  [CampaignMutation.SET](state, campaigns) {
    state.campaigns = campaigns;
  },
  [CampaignMutation.UPDATE](state, campaign) {
    const index = state.campaigns.findIndex((el) => el.id === campaign.id);
    Object.assign(state.campaigns[index], campaign);
  },
  [CampaignMutation.REMOVE](state, id) {
    const campaigns = state.campaigns.filter((el) => el.id !== id);
    state.campaigns = campaigns;
  },
  [CampaignMutation.SET_PRODUCTS](state, products) {
    state.products = products;
  },
  [CampaignMutation.ADD_PRODUCT](state, product) {
    state.products.unshift(product);
  },
  [CampaignMutation.ADD_PRODUCT_GROUP](state, group) {
    state.productGroups.unshift(group);
  },
  [CampaignMutation.ADD_PRODUCTS](state, products) {
    state.products.unshift(...products);
  },
  [CampaignMutation.REMOVE_PRODUCT](state, id) {
    const products = state.products.filter((el) => el.id !== id);
    state.products = products;
  },
  [CampaignMutation.REMOVE_PRODUCT_GROUP](state, id) {
    const productGroups = state.productGroups.filter((el) => el.id !== id);
    state.productGroups = productGroups;
  },
  [CampaignMutation.SET_STATUS_REVIEW](state, statusReview) {
    state.statusReview = statusReview;
  },
  [CampaignMutation.SET_OFFLINE_ACTIVITIES](state, activities) {
    state.offlineActivities = activities;
  },
  [CampaignMutation.ADD_OFFLINE_ACTIVITY](state, activity) {
    state.offlineActivities.unshift(activity);
  },
  [CampaignMutation.UPDATE_OFFLINE_ACTIVITY](state, activity) {
    const index = state.offlineActivities.findIndex(
      (el) => el.id === activity.id
    );
    Object.assign(state.offlineActivities[index], activity);
  },
  [CampaignMutation.REMOVE_OFFLINE_ACTIVITY](state, activityId) {
    const activities = state.offlineActivities.filter(
      (el) => el.id !== activityId
    );
    state.offlineActivities = activities;
  },
  [CampaignMutation.SET_SUMMARY](state, summary) {
    state.summary = summary;
  },
  [CampaignMutation.SET_PRODUCT_GROUPS](state, groups) {
    state.productGroups = groups;
  },
};
