import axios from '@/axios';
import { CampaignAction } from '@/store/actionTypes';
import { CampaignMutation } from '@/store/mutationTypes';

export default {
  [CampaignAction.add]({ commit }, item) {
    return new Promise((resolve, reject) => {
      return axios
        .post('/campaigns', item)
        .then((response) => {
          commit(CampaignMutation.ADD, response.data);
          resolve(response);
        })
        .catch(reject);
    });
  },
  [CampaignAction.fetchOne](context, id) {
    return new Promise((resolve, reject) => {
      return axios.get(`/campaigns/${id}`).then(resolve).catch(reject);
    });
  },
  [CampaignAction.fetch]({ commit }, query = '') {
    return new Promise((resolve, reject) => {
      return axios
        .get(`/campaigns${query}`)
        .then((response) => {
          commit(CampaignMutation.SET, response.data);
          resolve(response);
        })
        .catch(reject);
    });
  },
  [CampaignAction.update]({ commit }, item) {
    return new Promise((resolve, reject) => {
      return axios
        .put(`/campaigns/${item.id}`, item)
        .then((response) => {
          commit(CampaignMutation.UPDATE, response.data);
          resolve(response);
        })
        .catch(reject);
    });
  },
  [CampaignAction.remove]({ commit }, id) {
    return new Promise((resolve, reject) => {
      return axios
        .delete(`/campaigns/${id}`)
        .then((response) => {
          commit(CampaignMutation.REMOVE, id);
          resolve(response);
        })
        .catch(reject);
    });
  },
  [CampaignAction.fetchProducts]({ commit }, campaignId) {
    return new Promise((resolve, reject) => {
      return axios
        .get(`/campaigns/${campaignId}/products`)
        .then(({ data }) => {
          commit(CampaignMutation.SET_PRODUCTS, data);
          resolve(data);
        })
        .catch(reject);
    });
  },
  [CampaignAction.addProduct]({ commit }, item) {
    return new Promise((resolve, reject) => {
      return axios
        .post(`/campaigns/${item.campaignId}/products`, item)
        .then((response) => {
          commit(CampaignMutation.ADD_PRODUCT, response.data);
          resolve(response);
        })
        .catch(reject);
    });
  },
  [CampaignAction.fetchProductGroups]({ commit }, campaignId) {
    return new Promise((resolve, reject) => {
      return axios
        .get(`/campaigns/${campaignId}/product-groups`)
        .then(({ data }) => {
          commit(CampaignMutation.SET_PRODUCT_GROUPS, data);
          resolve(data);
        })
        .catch(reject);
    });
  },
  [CampaignAction.addProductGroup]({ commit }, { campaignId, productGroupId }) {
    return new Promise((resolve, reject) => {
      return axios
        .post(`/campaigns/${campaignId}/product-groups/${productGroupId}`)
        .then(({ data }) => {
          commit(CampaignMutation.ADD_PRODUCT_GROUP, data);
          resolve(data);
        })
        .catch(reject);
    });
  },
  [CampaignAction.removeProductGroup]({ commit }, productGroupId) {
    this._vm.$vs.loading();

    return new Promise((resolve, reject) => {
      return axios
        .delete(`/campaigns/product-groups/${productGroupId}`)
        .then((response) => {
          commit(CampaignMutation.REMOVE_PRODUCT_GROUP, productGroupId);
          resolve(response);
        })
        .catch(reject)
        .finally(() => this._vm.$vs.loading.close());
    });
  },
  [CampaignAction.removeProduct]({ commit }, productId) {
    this._vm.$vs.loading();

    return new Promise((resolve, reject) => {
      return axios
        .delete(`/campaigns/products/${productId}`)
        .then((response) => {
          commit(CampaignMutation.REMOVE_PRODUCT, productId);
          resolve(response);
        })
        .catch(reject)
        .finally(() => this._vm.$vs.loading.close());
    });
  },
  [CampaignAction.fetchProductConfig](context, productId) {
    this._vm.$vs.loading();

    return new Promise((resolve, reject) => {
      return axios
        .get(`/campaigns/products/${productId}/config`)
        .then(({ data }) => resolve(data))
        .catch(reject)
        .finally(() => this._vm.$vs.loading.close());
    });
  },
  [CampaignAction.fetchProductGroupConfig](context, productGroupId) {
    this._vm.$vs.loading();

    return new Promise((resolve, reject) => {
      return axios
        .get(`/campaigns/product-groups/${productGroupId}/config`)
        .then(({ data }) => resolve(data))
        .catch(reject)
        .finally(() => this._vm.$vs.loading.close());
    });
  },
  [CampaignAction.changeProductConfigTab](context, item) {
    this._vm.$vs.loading();
    return new Promise((resolve, reject) => {
      return axios
        .post('/campaigns/products/config/tab', item)
        .then(({ data }) => resolve(data))
        .catch(reject);
    });
  },
  [CampaignAction.updateProductComment](context, item) {
    this._vm.$vs.loading();

    return new Promise((resolve, reject) => {
      return axios
        .post(`/campaigns/products/${item.id}/comments`, item)
        .then(({ data }) => resolve(data))
        .catch(reject)
        .finally(() => this._vm.$vs.loading.close());
    });
  },
  [CampaignAction.addStatusSearch](context, item) {
    return new Promise((resolve, reject) => {
      return axios
        .post(
          `/campaigns/products/${item.campaignProductId}/status-search`,
          item
        )
        .then(({ data }) => resolve(data))
        .catch(reject);
    });
  },
  [CampaignAction.updateStatusSearch](context, item) {
    return new Promise((resolve, reject) => {
      return axios
        .put(
          `/campaigns/products/${item.campaignProductId}/status-search/${item.id}`,
          item
        )
        .then(({ data }) => resolve(data))
        .catch(reject);
    });
  },
  [CampaignAction.removeStatusSearch](context, item) {
    this._vm.$vs.loading();

    return new Promise((resolve, reject) => {
      return axios
        .delete(
          `/campaigns/products/${item.campaignProductId}/status-search/${item.id}`
        )
        .then(({ data }) => resolve(data))
        .catch(reject)
        .finally(() => this._vm.$vs.loading.close());
    });
  },
  [CampaignAction.fetchStatusReviews]({ commit }, campaignId) {
    return new Promise((resolve, reject) => {
      return axios
        .get(`/campaigns/${campaignId}/status-review`)
        .then((response) => {
          commit(CampaignMutation.SET_STATUS_REVIEW, response.data);
          resolve(response);
        })
        .catch(reject);
    });
  },
  [CampaignAction.updateStatusReview]({ commit }, item) {
    return new Promise((resolve, reject) => {
      return axios
        .put(`/campaigns/${item.id}/status-review`, item)
        .then(({ data }) => {
          commit(CampaignMutation.SET_STATUS_REVIEW, data);
          resolve(data);
        })
        .catch(reject);
    });
  },
  [CampaignAction.fetchOfflineActivities]({ commit }, campaignId) {
    return new Promise((resolve, reject) => {
      return axios
        .get(`/campaigns/${campaignId}/offline-activities`)
        .then(({ data }) => {
          commit(CampaignMutation.SET_OFFLINE_ACTIVITIES, data);
          resolve(data);
        })
        .catch(reject);
    });
  },
  [CampaignAction.addOfflineActivity]({ commit }, item) {
    return new Promise((resolve, reject) => {
      return axios
        .post(`/campaigns/offline-activities/${item.id}`, item)
        .then((response) => {
          commit(CampaignMutation.ADD_OFFLINE_ACTIVITY, response.data);
          resolve(response);
        })
        .catch(reject);
    });
  },
  [CampaignAction.updateOfflineActivity]({ commit }, item) {
    return new Promise((resolve, reject) => {
      return axios
        .put(`/campaigns/offline-activities/${item.id}`, item)
        .then((response) => {
          commit('UPDATE_OFFLINE_ACTIVITY', response.data);
          resolve(response);
        })
        .catch(reject);
    });
  },
  [CampaignAction.removeOfflineActivity]({ commit }, id) {
    this._vm.$vs.loading();

    return new Promise((resolve, reject) => {
      axios
        .delete(`/campaigns/offline-activities/${id}`)
        .then((response) => {
          commit(CampaignMutation.REMOVE_OFFLINE_ACTIVITY, id);
          resolve(response);
        })
        .catch(reject)
        .finally(() => this._vm.$vs.loading.close());
    });
  },
  [CampaignAction.addDisplayAndPaidSearchPlanning](context, item) {
    return new Promise((resolve, reject) => {
      return axios
        .post('/campaigns/products/display-paid-search-planning', item)
        .then(({ data }) => resolve(data))
        .catch(reject);
    });
  },
  [CampaignAction.updateDisplayAndPaidSearchPlanning](context, item) {
    return new Promise((resolve, reject) => {
      return axios
        .put(
          `/campaigns/products/display-paid-search-planning/${item.id}`,
          item
        )
        .then(({ data }) => resolve(data))
        .catch(reject);
    });
  },
  [CampaignAction.removeDisplayAndPaidSearchPlanning](context, id) {
    this._vm.$vs.loading();

    return new Promise((resolve, reject) => {
      return axios
        .delete(`/campaigns/products/display-paid-search-planning/${id}`)
        .then(resolve)
        .catch(reject)
        .finally(() => this._vm.$vs.loading.close());
    });
  },
  [CampaignAction.fetchDisplayAndPaidSearchResult](context, item) {
    this._vm.$vs.loading();

    return new Promise((resolve, reject) => {
      return axios
        .post('/campaigns/products/display-paid-search-result', item)
        .then(({ data }) => resolve(data))
        .catch(reject)
        .finally(() => this._vm.$vs.loading.close());
    });
  },
  [CampaignAction.updateDisplayAndPaidSearchResult](context, item) {
    return new Promise((resolve, reject) => {
      return axios
        .put(`/campaigns/products/display-paid-search-result/${item.id}`, item)
        .then(({ data }) => resolve(data))
        .catch(reject);
    });
  },
  [CampaignAction.addCrmPlanning](context, item) {
    return new Promise((resolve, reject) => {
      return axios
        .post('/campaigns/products/crm-planning', item)
        .then(({ data }) => resolve(data))
        .catch(reject);
    });
  },
  [CampaignAction.updateCrmPlanning](context, item) {
    return new Promise((resolve, reject) => {
      return axios
        .put(`/campaigns/products/crm-planning/${item.id}`, item)
        .then(({ data }) => resolve(data))
        .catch(reject);
    });
  },
  [CampaignAction.removeCrmPlanning](context, id) {
    this._vm.$vs.loading();

    return new Promise((resolve, reject) => {
      return axios
        .delete(`/campaigns/products/crm-planning/${id}`)
        .then(({ data }) => resolve(data))
        .catch(reject)
        .finally(() => this._vm.$vs.loading.close());
    });
  },
  [CampaignAction.fetchCrmResult](context, item) {
    this._vm.$vs.loading();

    return new Promise((resolve, reject) => {
      return axios
        .post('/campaigns/products/crm-result', item)
        .then(({ data }) => resolve(data))
        .catch(reject)
        .finally(() => this._vm.$vs.loading.close());
    });
  },
  [CampaignAction.updateCrmResult](context, item) {
    return new Promise((resolve, reject) => {
      return axios
        .put(`/campaigns/products/crm-result/${item.id}`, item)
        .then(({ data }) => resolve(data))
        .catch(reject);
    });
  },
  [CampaignAction.addSocialMediaPlanning](context, item) {
    return new Promise((resolve, reject) => {
      return axios
        .post('/campaigns/products/social-media-planning', item)
        .then(({ data }) => resolve(data))
        .catch(reject);
    });
  },
  [CampaignAction.updateSocialMediaPlanning](context, item) {
    return new Promise((resolve, reject) => {
      return axios
        .put(`/campaigns/products/social-media-planning/${item.id}`, item)
        .then(({ data }) => resolve(data))
        .catch(reject);
    });
  },
  [CampaignAction.removeSocialMediaPlanning](context, id) {
    this._vm.$vs.loading();

    return new Promise((resolve, reject) => {
      return axios
        .delete(`/campaigns/products/social-media-planning/${id}`)
        .then(({ data }) => resolve(data))
        .catch(reject)
        .finally(() => this._vm.$vs.loading.close());
    });
  },
  [CampaignAction.fetchSocialMediaResult](context, item) {
    this._vm.$vs.loading();

    return new Promise((resolve, reject) => {
      return axios
        .post('/campaigns/products/social-media-result', item)
        .then(({ data }) => resolve(data))
        .catch(reject)
        .finally(() => this._vm.$vs.loading.close());
    });
  },
  [CampaignAction.updateSocialMediaResult](context, item) {
    return new Promise((resolve, reject) => {
      return axios
        .put(`/campaigns/products/social-media-result/${item.id}`, item)
        .then(({ data }) => resolve(data))
        .catch(reject);
    });
  },
  [CampaignAction.addReviewPlanningItem](context, item) {
    return new Promise((resolve, reject) => {
      return axios
        .post('/campaigns/products/review-planning-item', item)
        .then(({ data }) => resolve(data))
        .catch(reject);
    });
  },
  [CampaignAction.updateReviewPlanningItem](context, item) {
    return new Promise((resolve, reject) => {
      return axios
        .put(`/campaigns/products/review-planning-item/${item.id}`, item)
        .then(({ data }) => resolve(data))
        .catch(reject);
    });
  },
  [CampaignAction.removeReviewPlanningItem](context, id) {
    this._vm.$vs.loading();

    return new Promise((resolve, reject) => {
      return axios
        .delete(`/campaigns/products/review-planning-item/${id}`)
        .then(({ data }) => resolve(data))
        .catch(reject)
        .finally(() => this._vm.$vs.loading.close());
    });
  },
  [CampaignAction.addReviewResultItem](context, item) {
    return new Promise((resolve, reject) => {
      return axios
        .post('/campaigns/products/review-result-item', item)
        .then(({ data }) => resolve(data))
        .catch(reject);
    });
  },
  [CampaignAction.updateReviewResultItem](context, item) {
    return new Promise((resolve, reject) => {
      return axios
        .put(`/campaigns/products/review-result-item/${item.id}`, item)
        .then(({ data }) => resolve(data))
        .catch(reject);
    });
  },
  [CampaignAction.removeReviewResultItem](context, id) {
    this._vm.$vs.loading();

    return new Promise((resolve, reject) => {
      return axios
        .delete(`/campaigns/products/review-result-item/${id}`)
        .then(({ data }) => resolve(data))
        .catch(reject)
        .finally(() => this._vm.$vs.loading.close());
    });
  },
  [CampaignAction.fetchSummary]({ commit }, campaignId) {
    return new Promise((resolve, reject) => {
      return axios
        .get(`/campaigns/${campaignId}/summary`)
        .then((response) => {
          commit(CampaignMutation.SET_SUMMARY, response.data);
          resolve(response);
        })
        .catch(reject);
    });
  },
  [CampaignAction.updateSummaryOfflineActivity]({ commit }, item) {
    this._vm.$vs.loading();

    return new Promise((resolve, reject) => {
      return axios
        .put(`/campaigns/${item.campaignId}/summary/offline-activities`, item)
        .then((response) => {
          commit(CampaignMutation.SET_SUMMARY, response.data);
          resolve(response);
        })
        .catch(reject)
        .finally(() => this._vm.$vs.loading.close());
    });
  },
  [CampaignAction.fetchFiles]({ commit }, campaignId) {
    return new Promise((resolve, reject) => {
      return axios
        .get(`/campaigns/${campaignId}/files`)
        .then(({ data }) => resolve(data))
        .catch(reject);
    });
  },
  [CampaignAction.uploadFiles]({ commit }, { campaignId, files }) {
    this._vm.$vs.loading();

    return new Promise((resolve, reject) => {
      return axios
        .post(`/campaigns/${campaignId}/files`, files, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(({ data }) => resolve(data))
        .catch(reject)
        .finally(() => this._vm.$vs.loading.close());
    });
  },
  [CampaignAction.removeFile]({ commit }, { campaignId, fileId }) {
    this._vm.$vs.loading();

    return new Promise((resolve, reject) => {
      return axios
        .delete(`/campaigns/${campaignId}/files/${fileId}`)
        .then(({ data }) => resolve(data))
        .catch(reject)
        .finally(() => this._vm.$vs.loading.close());
    });
  },
  [CampaignAction.downloadFile]({ commit }, { campaignId, fileId }) {
    this._vm.$vs.loading();

    return new Promise((resolve, reject) => {
      return axios
        .get(`/campaigns/${campaignId}/files/${fileId}/download`, {
          responseType: 'blob',
        })
        .then(resolve)
        .catch(reject)
        .finally(() => this._vm.$vs.loading.close());
    });
  },
};
